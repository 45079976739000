var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list-v1', $(this));

    if ($statusList.length === 0) {
      return;
    }
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list-v1', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list-v1', $(this));

    if ($statusList.length === 0) {
      return;
    }
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  });

  $(document).on('mouseout', '.js-swatch-list-item-link', function () {
    var $swatchList = $(this).closest('.js-swatch-list');
    var skuBaseId = $swatchList.data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchList);

    $productEl.trigger('product.skuDisplay', skuBaseId);
  });

  Drupal.behaviors.inventoryStatusV1 = {

    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      if (!skuData || !skuData.INVENTORY_STATUS) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      $('li', $statusList).hide();
      var $statusToShow = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

      if ($statusToShow) {
        $statusToShow.show();
        $statusList.trigger('inv_status_display:updated');
      }
      if (Drupal.settings.product_display.has_limited_remaining && !!skuData.isLimitedRemaining) {
        var threshold_range = site.translations.product.threshold_range_few_left.split(',');

        if (skuData.highVelocityQty > threshold_range[1] && skuData.highVelocityQty <= threshold_range[0]) {
          $('.js-limited-remaining-highest', $statusList).show();
        } else if (skuData.highVelocityQty <= site.translations.product.threshold_limit_only_left) {
          $('.js-threshold-count').text(skuData.highVelocityQty);
          $('.js-limited-remaining-lowest', $statusList).show();
        }
      }
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);

      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
    }
  };
})(jQuery);
